$(document).ready(function(){
	//GSAP
	gsap.registerPlugin(ScrollTrigger);
	const tl = gsap.timeline();
	tl.to(".cover",{autoAlpha: 0,duration:0.8,delay:0.3});
  if($('#home').length){
		//キービジュアル
		gsap.set(".key-tit", { x: "-100%" });
		tl.fromTo(".key-tit",{x:"-100%"},{x:0,duration:0.5,delay:0.3,ease:"power2.out"});
		tl.to(":root",{"--key-tit-cover-left":"100%",duration:0.5,ease:"power2.out"});
		
		gsap.set(".section-anime", { opacity: "0",y: 50 });
		gsap.utils.toArray(".section-anime").forEach((elm) => {
			let tl2 = gsap.timeline({
				scrollTrigger:{
					trigger:elm,
					start:'top center',
					toggleClass: {targets: elm, className: "is-show"},
	        once: true,
					start: 'top bottom-=20%',
					//markers: true,
				}
			});
			tl2.to(elm,{y:0, opacity:1, ease:"power1.out"})
			.add(() => {
				$(".top-tit-en",elm).addClass('is-show');
				$(".top-tit-jp",elm).addClass('is-show');
			});
			if(elm.id=="top-about"){
				

			}else if(elm.id=="top-menu"){

			}
		});
		//About


	}


});